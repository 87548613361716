import React from "react";
import { graphql } from "gatsby";
import PortableText from "../components/portableText";
import SEO from "../components/seo";
import SeoContext from "../components/context/seoContext";

const IndexPagec = (props) => {
  const { sanityPage, sanitySiteSettings = {} } = props.data;
  const { _rawBody, title, seo } = sanityPage;

  sanitySiteSettings.pageTitle = `${title} | ${sanitySiteSettings.title || "Siguna"}`;

  return (
    <SeoContext.Provider value={sanitySiteSettings}>
      <SEO title={title} description={seo?.description} keywords={seo?.tags} />
      {_rawBody && <PortableText blocks={_rawBody} />}
    </SeoContext.Provider>
  );
};

export default IndexPagec;
export const query = graphql`
  query {
    sanityPage(special: { eq: "homepage" }) {
      title
      seo {
        description
        tags
      }
      _rawBody(resolveReferences: { maxDepth: 10 })
    }
    sanitySiteSettings {
      ...Settings
    }
  }
`;
